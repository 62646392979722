@font-face {
  font-family: "SpoqaHanSansNeoBold";
  src: url(./Spoqa/SpoqaHanSansNeo-Bold.ttf);
}

@font-face {
  font-family: "SpoqaHanSansNeoLight";
  src: url(./Spoqa/SpoqaHanSansNeo-Light.ttf);
}

@font-face {
  font-family: "SpoqaHanSansNeoMedium";
  src: url(./Spoqa/SpoqaHanSansNeo-Medium.ttf);
}

@font-face {
  font-family: "SpoqaHanSansNeoRegular";
  src: url(./Spoqa/SpoqaHanSansNeo-Regular.ttf);
}

@font-face {
  font-family: "SpoqaHanSansNeoThin";
  src: url(./Spoqa/SpoqaHanSansNeo-Thin.ttf);
}
@font-face {
  font-family: Pretendard800;
  src: url(./Pretendard/Pretendard-ExtraBold.woff);
}
@font-face {
  font-family: Pretendard700;
  src: url(./Pretendard/Pretendard-Bold.woff);
}

@font-face {
  font-family: Pretendard600;
  src: url(./Pretendard/Pretendard-SemiBold.woff);
}

@font-face {
  font-family: Pretendard500;
  src: url(./Pretendard/Pretendard-Medium.woff);
}

@font-face {
  font-family: Pretendard400;
  src: url(./Pretendard/Pretendard-Regular.woff);
}

@font-face {
  font-family: Pretendard300;
  src: url(./Pretendard/Pretendard-Regular.woff);
}


@font-face {
  font-family: GmarketSans;
  font-weight: bold;
  src: url(./GmarketSans/GmarketSansTTFBold.ttf);
}

@font-face {
  font-family: GmarketSans;
  font-weight: bold;
  src: url(./GmarketSans/GmarketSansTTFLight.ttf);
}

@font-face {
  font-family: GmarketSans;
  font-weight: bold;
  src: url(./GmarketSans/GmarketSansTTFMedium.ttf);
}
@font-face {
  font-family: LabDigital;
  src: url(./Lab/LABDigital.ttf);
}

